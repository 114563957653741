import { translateCriticalErrors } from "../../../utils";
import { Box, Heading } from "@chakra-ui/react";
import { Link } from "gatsby";
import React from "react";

const ApplicationStatus = ({ data, platform }) => {
  const statusObj = translateCriticalErrors(
    data?.stopped_reason,
    platform.name
  );

  if (data.error) {
    return (
      <Box bg="red.500" p={3} mt={6} rounded="md" color="white">
        <Heading size="md">Estado de la aplicación:</Heading>

        <dl className="sm:divide-y sm:divide-gray-200">
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
            <dt className="text-sm font-medium">Titulo</dt>
            <dd className="mt-1 text-sm sm:col-span-2 sm:mt-0">
              Ha habido un error inesperado, por favor comunícate con soporte y
              muestrales la descripción del error
            </dd>
          </div>
          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
            <dt className="text-sm font-medium">Descripción</dt>
            <dd className="mt-1 text-sm sm:col-span-2 sm:mt-0">{data.error}</dd>
          </div>
        </dl>
      </Box>
    );
  }

  return (
    <>
      {data.jobs_with_response ? (
        <Box
          bg={statusObj.bg}
          p={3}
          mt={6}
          rounded="md"
          color={statusObj.color}
        >
          <Heading size="md">Estado de la aplicación:</Heading>

          <dl className="sm:divide-y sm:divide-gray-200">
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
              <dt className="text-sm font-medium">Titulo</dt>
              <dd className="mt-1 text-sm sm:col-span-2 sm:mt-0">
                {statusObj.title}
              </dd>
            </div>
            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
              <dt className="text-sm font-medium">Descripción</dt>
              <dd className="mt-1 text-sm sm:col-span-2 sm:mt-0">
                {statusObj.description}
              </dd>
            </div>
            {statusObj.action ? (
              <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                <dt className="text-sm font-medium">
                  Ve al siguiente link, para saber como solucionar este error:
                </dt>
                <dd className="mt-1 text-sm sm:col-span-2 sm:mt-0">
                  <Link to={statusObj.action}>Ir a</Link>
                </dd>
              </div>
            ) : null}
          </dl>
        </Box>
      ) : null}
    </>
  );
};

export default ApplicationStatus;

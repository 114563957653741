import React, { useEffect, useState } from "react";
import "./css/bootstrap.min.css";
import "./css/iofrm-style.css";
import "./css/iofrm-theme29.css";
import logolight from "./images/logo-light.svg";
import graphic from "./images/graphic3.svg";
import SEO from "../../seo";
import { Link } from "gatsby";
import { useForm } from "../../../hooks/useForm";
import { loginUsersSchema } from "../../../yup/schemas";
import { FormProvider } from "react-hook-form";
import InputField from "../../form/InputField";
import { Oval } from "react-loader-spinner";
import { client } from "../../../lib/client";
import { toast } from "react-hot-toast";
import { useAuth } from "../../../hooks/useAuth";
import useQuery from "../../../hooks/useQuery";

const Login = () => {
  const formMethods = useForm({
    schema: loginUsersSchema,
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const {
    formState: { isSubmitting },
  } = formMethods;

  const { login } = useAuth();

  const { query } = useQuery();

  useEffect(() => {
    if (query.get("alert")) {
      toast.error("Debes iniciar sesión");
    }
  }, [query]);

  const onSubmit = async (formData) => {
    try {
      const data = (await client.post("/login", formData)).data?.data;
      toast.success("¡Login exitoso! Redireccionando al dashboard");

      const user = data.user;
      const token = data.token?.token;
      login({ ...user, token }, () => {
        window.location.replace("/dash/apply");
      });
    } catch (e) {
      toast.error(
        e?.response?.data?.message ?? "Algo ha salido mal, verifica tus datos"
      );
      throw e;
    }
  };

  return (
    <FormProvider {...formMethods}>
      <SEO
        title="Login"
        description="Mass Job application. Aplica de forma instantanea a cientos de vacantes disponibles para ti. Agrega tus datos, aplica y mira como te llegan ofertas al instante."
      />
      <div className="form-body without-side">
        <div className="website-logo">
          <div className="logo">
            <img className="logo-size" src={logolight} alt="" />
          </div>
        </div>
        <div className="row">
          <div className="img-holder">
            <div className="bg"></div>
            <div className="info-holder">
              <img src={graphic} alt="" />
            </div>
          </div>
          <div className="form-holder">
            <div className="form-content">
              <div className="form-items">
                <h3>Bienvenid@ de vuelta</h3>
                <p>
                  Entra a tu cuenta y aplica a cientos de vacantes al insante.
                </p>
                <form
                  method="POST"
                  onSubmit={formMethods.handleSubmit(onSubmit)}
                >
                  <InputField
                    className="form-control"
                    type="text"
                    nameField="email"
                    required
                    placeholder={"Email"}
                    style={{ color: "#fff" }}
                    variant="input-unstyled"
                  />

                  <InputField
                    className="form-control"
                    type="password"
                    nameField="password"
                    required
                    placeholder={"Password"}
                    style={{ color: "#fff" }}
                    variant="input-unstyled"
                  />
                  <div className="form-button">
                    <button
                      id="submit"
                      type="submit"
                      className="btn btn-primary btn-block btn-sm"
                    >
                      {isSubmitting ? (
                        <Oval
                          height={20}
                          width={20}
                          color="#4fa94d"
                          wrapperStyle={{}}
                          wrapperClass=""
                          visible={true}
                          ariaLabel="oval-loading"
                          secondaryColor="#4fa94d"
                          strokeWidth={2}
                          strokeWidthSecondary={2}
                        />
                      ) : (
                        <div> Login</div>
                      )}
                    </button>
                  </div>
                </form>
                <div className="other-links"></div>
                <div className="page-links">
                  <Link to="/">¿No tienes cuenta?</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </FormProvider>
  );
};

export default Login;

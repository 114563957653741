import dayjs from "dayjs";

export const commonFormatDate = (date) => {
  return dayjs(date).format("DD/MM/YYYY hh:mm:ss");
};

export const determinePaymentStatus = (payment) => {
  const events = payment.events ?? [];

  const successEvent = events.find(
    (event) => event.status === "complete" && event.payment_status === "paid"
  );

  return successEvent
    ? { message: "Pago", paid: true, colorScheme: "green" }
    : { message: "No pago", colorScheme: "red", paid: false };
};

export function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const sortArrByDate = (arr, field = "createdAt") => {
  return arr.sort(function (a, b) {
    return new Date(b[field]).getTime() - new Date(a[field]).getTime();
  });
};

export const translateCriticalErrors = (error, platformName) => {
  const defaultOption = {
    title: "Todo perfecto!",
    description: "La postulación a los trabajos no tuvo ningún problema",
    bg: "green.500",
    color: "white",
  };

  return (
    {
      ["login_fails"]: {
        title: "Error en el login",
        description: "Las credenciales de tu cuenta no coinciden :/",
        bg: "red.500",
        color: "white",
      },
      ["browser_null"]: {
        title: "Error inesperado",
        description:
          "Por favor contáctanos, debido a la demanda, puede suceder que nuestros servidores fallen :/",
        bg: "red.500",
        color: "white",
      },
      ["profile_not_configured"]: {
        title: "Error en el login",
        description: "Error en la configuración del perfil",
        bg: "red.500",
        color: "white",
        action: `/dash/guide/${platformName}`,
      },
    }[error] ?? defaultOption
  );
};

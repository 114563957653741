import React from "react";

import SEO from "../../../components/seo";
import { Text, Div, Anchor, Icon } from "atomize";
import DocsWrapper from "../../../components/common/DocsWrapper";
import { Link, graphql, useStaticQuery } from "gatsby";

const GuideOCC = () => {
  const { markdownRemark } = useStaticQuery(
    graphql`
      query {
        markdownRemark(frontmatter: { slug: { eq: "/occ-tutorial" } }) {
          html
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            slug
            title
          }
        }
      }
    `
  );

  return (
    <>
      <SEO title="Guía" description="Guía de " />
      <DocsWrapper>
        <Div p={{ x: { xs: "1.5rem", md: "4rem" }, t: "5rem", b: "10rem" }}>
          <span
            role="img"
            aria-label="wave"
            style={{ fontSize: 55 }}
            m={{ b: "1.5rem" }}
          >
            📖
          </span>
          <Text
            tag="h2"
            textWeight="400"
            textSize="display2"
            m={{ b: "1rem", t: "1rem" }}
          >
            Guía OCC
          </Text>
          <Text
            tag="h3"
            textWeight="400"
            textSize="heading"
            textWeight="500"
            m={{ b: "0.5rem" }}
          >
            ¿Cómo funciona?
          </Text>
          <Text m={{ b: "1rem" }} textColor="medium">
            Nuestro bot de aplicación massiva manda aplicaciones de forma
            automatizada a plataformas de trabajo reconocidas como:
          </Text>
          <Div m={{ b: "1rem" }}>
            <Anchor href="http://occ.com.mx" target="_blank" d="block">
              OCC Mundial
            </Anchor>
            <Anchor href="https://mx.computrabajo.com" target="_blank" d="block">
              Computrabajo
            </Anchor>
            {/*        <Anchor href="http://indeed.com" target="_blank" d="block">
              Indeed
            </Anchor>*/}
          </Div>
          <Text m={{ b: "1rem" }} textColor="medium">
            Por lo que es indispensable que tengas ACTIVAS y COMPLETADAS tus
            cuentas. A continuación te presentamos una guía por plataforma para
            asegurar que tengas toda tu información completada.
          </Text>
          <Div d="flex" bg="info200" p="1rem" rounded="md" m={{ t: "4rem" }}>
            <Icon
              name="InfoSolid"
              size="16px"
              m={{ r: "0.5rem", t: "0.25rem" }}
              color="info700"
            />
            <Text>
              IMPORTANTE: Debes estar pendiente a tu correo o la plataforma de OCC para ver las ofertas
              y notificaciones importantes.
            </Text>
          </Div>
          <Div
            m={{ b: "4rem" }}
            p={{ x: "0.5rem" }}
            bg="gray200"
            rounded="lg"
            overflow="hidden"
            textColor="medium"
            fontFamily="code"
          ></Div>
          <Text
            tag="h3"
            textWeight="400"
            textSize="heading"
            textWeight="500"
            m={{ b: "0.5rem" }}
          >
            Guía OCC - Registro y Completado de Cuenta
          </Text>
          <div dangerouslySetInnerHTML={{ __html: markdownRemark?.html }} />
        </Div>
      </DocsWrapper>
    </>
  );
};

export default GuideOCC;

import React from "react";
import { navigate } from "gatsby";

const PrivateRoute = ({
  component: Component,
  location,
  isLoggedIn,
  ...rest
}) => {
  if (!isLoggedIn && location.pathname !== `/dash/login`) {
    navigate("/dash/login?alert=true");
    return null;
  }

  return <Component {...rest} />;
};

export default PrivateRoute;

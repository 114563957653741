import React from "react";
import SEO from "../../components/seo";
import { Link } from "gatsby";
import { Text, Div, Anchor, Icon } from "atomize";
import DocsWrapper from "../../components/common/DocsWrapper";
import TrustBadgeImage from "../../images/stripe/stripe-badge-transparent.png";
import Layout from "../layout"

const Dash = () => (
  <>
    <SEO title="Dash" description="¡Bienvenido a FileHire!" />
    <DocsWrapper>
      <Div p={{ x: { xs: "1.5rem", md: "4rem" }, t: "5rem", b: "10rem" }}>
        <img
          src={TrustBadgeImage}
          alt="Trust Badge"
          style={{
            width: "300px",     // Adjust the width as needed
            height: "auto",     // Automatically adjust the height
            position: "absolute",
            top: 75,
            right: 0,
          }}/>
        <span
          role="img"
          aria-label="wave"
          style={{ fontSize: 55 }}
          m={{ b: "1.5rem" }}
        >
          👋
        </span>
        <Text
          tag="h2"
          textWeight="400"
          textSize="display2"
          m={{ b: "1rem", t: "1rem" }}
        >
          ¡Comienza aquí!
        </Text>

        {/* Installation */}
        <Text tag="h3" textWeight="400" textSize="heading" m={{ b: "0.5rem" }}>
          ¡Bienvenido/a a FileHire!
        </Text>
        <Text m={{ b: "1rem" }} textColor="medium">
          ¡Gracias por probar nuestra version alpha abierta, eres increible!
        </Text>
        <Div d="flex" bg="success200" p="1rem" rounded="md" m={{ b: "2rem" }}>
          <Icon
            name="InfoSolid"
            size="16px"
            m={{ r: "0.5rem", t: "0.25rem" }}
            color="success700"
          />
          <Text>
            ¡La aplicacion ya está disponible! para empezar a recibir oferta va
            a la <Link href="/dash/apply">Aplicación Masiva</Link>
          </Text>
        </Div>
        <Div d="flex" bg="info200" p="1rem" rounded="md">
          <Icon
            name="InfoSolid"
            size="16px"
            m={{ r: "0.5rem", t: "0.25rem" }}
            color="info700"
          />
          <Text>
            IMPORTANTE: FileHire se encuentra en constante desarrollo para brindarte una mejor experiencia.
            Algunas funciones aún están en desarrollo.{" "}
          </Text>
        </Div>
        <Div
          m={{ b: "4rem" }}
          p={{ x: "0.5rem" }}
          bg="gray200"
          rounded="lg"
          overflow="hidden"
          textColor="medium"
          fontFamily="code"
        ></Div>
        {/* How it works */}
        <Text tag="h3" textWeight="400" textSize="heading" m={{ b: "0.5rem" }}>
          ¿Cómo funciona?
        </Text>
        <Text m={{ b: "1rem" }} textColor="medium">
          Nuestro bot de aplicación masiva manda aplicaciones de forma
          automatizada a plataformas de trabajo reconocidas como:
        </Text>
        <Div m={{ b: "1rem" }}>
          <Anchor href="http://occ.com.mx" target="_blank" d="block">
            OCC Mundial
          </Anchor>
          <Anchor href="https://mx.computrabajo.com" target="_blank" d="block">
            Computrabajo
          </Anchor>
          {/*<Anchor
           href="http://indeed.com"
           target="_blank"
           d="block"
         >
          Indeed
         </Anchor>*/}
        </Div>
        <Text m={{ b: "4.5rem" }} textColor="medium">
          FileHire aplica de forma masiva y automatizada por ti aumentando tus
          probabilidades de encontrar el trabajo ideal para ti.
        </Text>
        <Div d="flex" bg="warning300" p="1rem" rounded="md">
          <Icon
            name="InfoSolid"
            size="16px"
            m={{ r: "0.5rem", t: "0.25rem" }}
            color="warning700"
          />
          <Text>
            IMPORTANTE: Antes de aplicar es indispensable que tengas una CUENTA
            ACTIVA Y COMPLETADA en estas plataformas.{" "}
            <Link to="/dash/guide/occ">Ver Guía</Link>
          </Text>
        </Div>
      </Div>
    </DocsWrapper>
  </>
);

export default Dash;

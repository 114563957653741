import {
  Box,
  Button,
  Flex,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import { Icon } from "atomize";

const headers = [
  { title: "Nombre de la compañia" },
  { title: "Descripción del trabajo" },
  { title: "Ubicación" },
  { title: "Posición" },
  { title: "Fecha de publicación" },
  { title: "Salario" },
  { title: "Error" },
  { title: "Acciones" },
];

const JobInformationItemModal = ({ text }) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  return (
    <Flex alignItems="center" experimental_spaceX={2}>
      <Modal isOpen={isOpen} onClose={onClose} size="6xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Texto completo</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text textWrap="nowrap">{text}</Text>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Cerrar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Tooltip label="Ver todo el texto">
        <Text
          onClick={onOpen}
          cursor="pointer"
          maxW="300px"
          overflow="hidden"
          textOverFlow="ellipsis"
          //  textDecoration="underline"
        >
          {text}
        </Text>
      </Tooltip>
    </Flex>
  );
};

const JobInformationTable = ({
  jobs = [],
  title,
  colorScheme,
  itemsPerPage = 15,
}) => {
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(jobs.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(jobs.length / itemsPerPage));
  }, [itemOffset, itemsPerPage]);

  if (jobs.length === 0) {
    return <Flex>No hay ningún trabajo para mostrar</Flex>;
  }

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % jobs.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };

  return (
    <>
      <TableContainer>
        <Table variant="striped" colorScheme={colorScheme}>
          <TableCaption>{title}</TableCaption>
          <Thead>
            <Tr>
              {headers.map((header, index) => (
                <Th {...header} key={index}>
                  {header.title}
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {currentItems?.map((job, index) => {
              const response = job.response;
              return (
                <Tr key={index}>
                  <Td>
                    <JobInformationItemModal text={job.companyName} />
                  </Td>
                  <Td>
                    <JobInformationItemModal text={job.aboutCompany} />
                  </Td>
                  <Td>
                    <JobInformationItemModal text={job.location} />
                  </Td>
                  <Td>
                    <JobInformationItemModal text={job.position} />
                  </Td>
                  <Td>
                    <JobInformationItemModal text={job.posted_at} />
                  </Td>
                  <Td>
                    <JobInformationItemModal text={job.salary} />
                  </Td>{" "}
                  <Td>
                    {response?.success ? (
                      "No hay error para mostrar"
                    ) : (
                      <JobInformationItemModal
                        text={
                          response?.message_to_user ??
                          response?.messageToUser ??
                          response?.message ??
                          "No hay error para mostrar"
                        }
                      />
                    )}
                  </Td>
                  <Td>
                    <Tooltip label="Ir al trabajo">
                      <a
                        href={job.link}
                        target="_blank"
                        referrerPolicy="no-referrer"
                      >
                        <IconButton
                          aria-label="Ir al trabajo"
                          colorScheme="blue"
                          icon={<Icon name="Link" size="20px" color="white" />}
                        />
                      </a>
                    </Tooltip>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
          <Tfoot>
            <Tr>
              {headers.map((header, index) => (
                <Th {...header} key={index}>
                  {header.title}
                </Th>
              ))}
            </Tr>
          </Tfoot>
        </Table>
      </TableContainer>
      <Flex mt={2} w="full" justifyContent="center">
        <ReactPaginate
          nextLabel="siguiente >"
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          marginPagesDisplayed={2}
          pageCount={pageCount}
          previousLabel="< anterior"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          containerClassName="pagination"
          activeClassName="active"
          renderOnZeroPageCount={null}
        />
      </Flex>
    </>
  );
};

const JobInformationModal = ({ isOpen, onClose, jobs, title, colorScheme }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="6xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {jobs ? (
            <JobInformationTable
              title={title}
              colorScheme={colorScheme}
              jobs={jobs}
            />
          ) : null}
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onClose}>
            Cerrar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default JobInformationModal;

import React from "react";
import { Link } from "gatsby";
import SEO from "../../components/seo";
import { Text, Div, Icon, Button } from "atomize";
import DocsWrapper from "../../components/common/DocsWrapper";
import TrustBadgeImage from "../../images/stripe/stripe-badge-transparent.png";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";

const Apply = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const redirectWizard = () => {
    const urlWizard = process.env.GATSBY_WIZARD_URL || "http://127.0.0.1:5173";
    const token = JSON.parse(localStorage.getItem("@filehire/user")).token;
    window.location.replace(`${urlWizard}?token=${token}`);
  };

  const addApplicationButton = (
    <Button
      type="button"
      className="bg-[#5A9E6B]"
      w="7rem"
      m={{ r: "1rem" }}
      onClick={onOpen}
    >
      ¡Aplicar!
    </Button>
  );
  return (
    <>
      <SEO title="Aplicación" description="File hire aplicaciones" />

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Nueva aplicación</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Serás redirigido al formulario de aplicacion donde podrás
            seleccionar las plataformas a las que quieras postularte y realizar
            tu pago.
          </ModalBody>

          <ModalFooter>
            <Button
              type="button"
              bg="transparent"
              border="1px solid"
              borderColor="gray400"
              textColor="medium"
              w="7rem"
              m={{ r: "1rem" }}
              onClick={onClose}
            >
              Cerrar
            </Button>
            <Button
              type="button"
              className="bg-[#5A9E6B]"
              w="7rem"
              onClick={redirectWizard}
            >
              Aplicar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <DocsWrapper>
        <Div p={{ x: { xs: "1.5rem", md: "4rem" }, t: "5rem", b: "10rem" }}>
          <img
            src={TrustBadgeImage}
            alt="Trust Badge"
            style={{
              width: "300px",     // Adjust the width as needed
              height: "auto",     // Automatically adjust the height
              position: "absolute",
              top: 75,
              right: 0,
            }}/>
          <span
            role="img"
            aria-label="envelope-with-arrow"
            style={{ fontSize: 55 }}
            m={{ b: "1.5rem" }}
          >
            📩
          </span>
          <Text
            tag="h2"
            textWeight="400"
            textSize="display2"
            m={{ b: "1rem", t: "1rem" }}
          >
            Aplicación Masiva.
          </Text>

          <Div d="flex" bg="success200" p="1rem" rounded="md" m={{ b: "2rem" }}>
            <Icon
              name="InfoSolid"
              size="16px"
              m={{ r: "0.5rem", t: "0.25rem" }}
              color="success700"
            />
            <Text>
              ¡La aplicacion ya está disponible! para cualquier duda o pedir
              ayuda entra al grupo de{" "}
              <a href="https://chat.whatsapp.com/H6O23bOjKqGHROfVwT6g28">
                Whatsapp
              </a>
            </Text>
          </Div>

          <Div d="flex" bg="info200" p="1rem" rounded="md" m={{ b: "2rem" }}>
            <Icon
              name="InfoSolid"
              size="16px"
              m={{ r: "0.5rem", t: "0.25rem" }}
              color="info700"
            />
            <Text>
              IMPORTANTE: FileHire se encuentra en constante desarrollo para brindarte una mejor experiencia.
              Algunas funciones aún están en desarrollo.{" "}
            </Text>
          </Div>

          <Div d="flex" m={{ b: "2rem" }}>
            <div>{addApplicationButton}</div>
            <Link to="/dash/guide/occ">
              <Button
                bg="transparent"
                border="1px solid"
                borderColor="gray400"
                textColor="medium"
                w="7rem"
              >
                Ver Guía
              </Button>
            </Link>
          </Div>

          <Div d="flex" bg="warning300" p="1rem" rounded="md">
            <Icon
              name="InfoSolid"
              size="16px"
              m={{ r: "0.5rem", t: "0.25rem" }}
              color="warning700"
            />
            <Text>
              IMPORTANTE: Antes de aplicar es indispensable que tengas una CUENTA
              ACTIVA Y COMPLETADA en estas plataformas.{" "}
              <Link to="/dash/guide/occ">Ver Guía</Link>
            </Text>
          </Div>
        </Div>
      </DocsWrapper>
    </>
  );
};

export default Apply;

import React from "react";
import { Router } from "@reach/router";
import Dash from "../../components/shared/dash";
import Layout from "../../components/layout";
import Events from "../../components/shared/events";
import EventDetails from "../../components/shared/event-details";
import Apply from "../../components/shared/apply";
import GuideOCC from "../../components/shared/guides/guide-occ";
import GuideComputrabajo from "../../components/shared/guides/guide-computrabajo";
import Login from "../../components/shared/auth/login";
import PrivateRoute from "../../router/PrivateRoute";
import { useAuth } from "../../hooks/useAuth";
import AuthRoute from "../../router/AuthRoute";
import { ChakraProvider } from "@chakra-ui/react";

const App = () => {
  const { isLoggedIn } = useAuth();

  return (
    <Router basepath="/dash">
      <PrivateRoute component={Dash} path="/" isLoggedIn={isLoggedIn} />

      <PrivateRoute
        component={EventDetails}
        path="/events/:id"
        isLoggedIn={isLoggedIn}
      />
      <PrivateRoute
        component={Events}
        path="/events"
        exact
        isLoggedIn={isLoggedIn}
      />

      <PrivateRoute component={Apply} path="/apply" isLoggedIn={isLoggedIn} />
      <PrivateRoute
        component={GuideOCC}
        path="/guide/occ"
        isLoggedIn={isLoggedIn}
      />
      <PrivateRoute
        component={GuideComputrabajo}
        path="/guide/computrabajo"
        isLoggedIn={isLoggedIn}
      />
      {/* <PrivateRoute
        component={GuideIndeed}
        path="/guide/indeed"
        isLoggedIn={isLoggedIn}
      />*/}
      <AuthRoute component={Login} path="/login" isLoggedIn={isLoggedIn} />
    </Router>
  );
};

export default function Wrapper() {
  return (
    <ChakraProvider>
      <Layout>
        <App />
      </Layout>
    </ChakraProvider>
  );
}

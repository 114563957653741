import React from "react";

import SEO from "../../../components/seo";
import { Text, Div, Anchor, Icon } from "atomize";
import DocsWrapper from "../../../components/common/DocsWrapper";
import { Link, graphql, useStaticQuery } from "gatsby";
import CT1 from "../../../images/ct-1.png";
import CT2 from "../../../images/ct-2.png";
import CT3 from "../../../images/ct-3.png";

const GuideComputrabajo = () => {
  const { markdownRemark } = useStaticQuery(
    graphql`
      query {
        markdownRemark(frontmatter: { slug: { eq: "/occ-tutorial" } }) {
          html
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            slug
            title
          }
        }
      }
    `
  );

  return (
    <>
      <SEO title="Guía" description="Guía de " />
      <DocsWrapper>
        <Div p={{ x: { xs: "1.5rem", md: "4rem" }, t: "5rem", b: "10rem" }}>
          <span
            role="img"
            aria-label="wave"
            style={{ fontSize: 55 }}
            m={{ b: "1.5rem" }}
          >
            📖
          </span>
          <Text
            tag="h2"
            textWeight="400"
            textSize="display2"
            m={{ b: "1rem", t: "1rem" }}
          >
            Guía Computrabajo
          </Text>
          <Text
            tag="h3"
            textWeight="400"
            textSize="heading"
            textWeight="500"
            m={{ b: "0.5rem" }}
          >
            ¿Cómo funciona?
          </Text>
          <Text m={{ b: "1rem" }} textColor="medium">
            Nuestro bot de aplicación massiva manda aplicaciones de forma
            automatizada a plataformas de trabajo reconocidas como:
          </Text>
          <Div m={{ b: "1rem" }}>
            <Anchor href="http://occ.com.mx" target="_blank" d="block">
              OCC Mundial
            </Anchor>
            <Anchor href="https://mx.computrabajo.com" target="_blank" d="block">
              Computrabajo
            </Anchor>
            {/*        <Anchor href="http://indeed.com" target="_blank" d="block">
              Indeed
            </Anchor>*/}
          </Div>
          <Text m={{ b: "1rem" }} textColor="medium">
            Por lo que es indispensable que tengas ACTIVAS y COMPLETADAS tus
            cuentas. A continuación te presentamos una guía por plataforma para
            asegurar que tengas toda tu información completada.
          </Text>
          <Div d="flex" bg="info200" p="1rem" rounded="md" m={{ t: "4rem" }}>
            <Icon
              name="InfoSolid"
              size="16px"
              m={{ r: "0.5rem", t: "0.25rem" }}
              color="info700"
            />
            <Text>
              IMPORTANTE: Debes estar pendiente a tu correo o la plataforma de Computrabajo para ver las ofertas
              y notificaciones importantes.
            </Text>
          </Div>
          <Div
            m={{ b: "4rem" }}
            p={{ x: "0.5rem" }}
            bg="gray200"
            rounded="lg"
            overflow="hidden"
            textColor="medium"
            fontFamily="code"
          ></Div>
          <Text
            tag="h3"
            textWeight="400"
            textSize="heading"
            textWeight="500"
            m={{ b: "0.5rem" }}
          >
            Guía Computrabajo - Registro y Completado de Cuenta
          </Text>

          <Text m={{ b: "1rem" }} textColor="medium">
            1.- Ingresa o crea una cuenta en <Anchor href="https://mx.computrabajo.com">Computrabajo</Anchor>
          </Text>
          <Div d="flex" bg="warning300" p="1rem" rounded="md">
            <Icon
              name="InfoSolid"
              size="16px"
              m={{ r: "0.5rem", t: "0.25rem" }}
              color="warning700"
            />
            <Text>[IMPORTANE] NO Iniciar sesión o crear una cuenta utilizando GOOGLE ya que NO está soportado en esta plataforma. Les pedimos NO usar el inicio de sesión de Google, si registraste tu cuenta con Google no podras hacer la aplicacion.
            </Text>
          </Div>
            <img
              src={CT1}
              alt="ct1"
              style={{
                width: "550px",
                height: "auto",
                marginTop: "10px"
              }}/>
              <Text m={{ b: "1rem", t: "1.25rem" }} textColor="medium">
                Entra con tu correo [NO USAR "Continuar con Google"]
              </Text>
            <img
              src={CT2}
              alt="ct2"
              style={{
                width: "550px",
                height: "auto",
                marginTop: "10px"
              }}/>
          <Text m={{ b: "1rem", t: "1.25rem" }} textColor="medium">
            2.- Crea y completa tu CV
          </Text>
          <img
            src={CT3}
            alt="ct3"
            style={{
              width: "550px",
              height: "auto",
            }}/>
          <Text m={{ b: "1rem" }} textColor="medium">
            3.- Una vez completado tu cv y tu correo verificado en Computrabajo, Regresa a FileHire y manda tu aplicacion masiva.
          </Text>
        </Div>
      </DocsWrapper>
    </>
  );
};

export default GuideComputrabajo;

import React from "react";
import { navigate } from "gatsby";

const AuthRoute = ({ component: Component, location, isLoggedIn, ...rest }) => {
  if (isLoggedIn && location.pathname === `/dash/login`) {
    navigate("/dash");
    return null;
  }

  return <Component {...rest} />;
};

export default AuthRoute;

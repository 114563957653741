import React from "react";
import SEO from "../../components/seo";
import { Div, Icon } from "atomize";
import DocsWrapper from "../../components/common/DocsWrapper";
import { client } from "../../lib/client";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Badge,
  Box,
  Heading,
  SimpleGrid,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import { useQuery } from "react-query";
import {
  classNames,
  commonFormatDate,
  determinePaymentStatus,
  sortArrByDate,
} from "../../utils";
import ApplicationStatus from "./events/ApplicationStatus";
import JobInformationModal from "./events/JobInformationModal";

const StatCard = ({ stat, jobs, colorScheme }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <li className="col-span-1 flex rounded-md shadow-sm">
      <JobInformationModal
        isOpen={isOpen}
        onClose={onClose}
        colorScheme={colorScheme}
        jobs={jobs}
        title={stat.name}
      />
      <div
        className={classNames(
          stat.bgColor,
          "flex-shrink-0 flex items-center justify-center w-16 text-white text-sm font-medium rounded-l-md"
        )}
      >
        {stat.initials}
      </div>
      <div className="flex flex-1 items-center justify-between truncate rounded-r-md border-t border-r border-b border-gray-200 bg-white">
        <div className="flex-1 truncate px-4 py-2 text-sm">
          {stat.name}
          <p className="text-gray-500">{stat.value}</p>
        </div>
        <div className="flex-shrink-0 pr-2">
          <Tooltip label="Ver más información">
            <button
              onClick={onOpen}
              type="button"
              className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-white bg-transparent text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              <Icon name="OptionsVertical" size="20px" />
              <span className="sr-only">Open options</span>
            </button>
          </Tooltip>
        </div>
      </div>
    </li>
  );
};

const Logs = ({ platform }) => {
  const { isLoading, data } = useQuery({
    queryKey: ["platform-logs", platform._id],
    queryFn: async ({ queryKey }) => {
      return (await client.get(`/payments/platform/${queryKey[1]}`)).data?.data;
    },
    enabled: true,
  });

  if (isLoading || !data) {
    return <Text mb={4}>No hay logs para mostrar </Text>;
  }
  return (
    <div className="">
      <Text mb={4}>Logs </Text>
      <ul role="list" className="-mb-8">
        {sortArrByDate(data).map((log, eventIdx) => {
          const data = log.data;

          if (data?.ignore) {
            return null;
          }
          return (
            <li key={log._id}>
              <div className="relative pb-8">
                {eventIdx !== platform.logs.length - 1 ? (
                  <span
                    className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
                    aria-hidden="true"
                  />
                ) : null}
                <div className="relative flex space-x-3">
                  <div>
                    <span
                      className={classNames(
                        "bg-blue-500",
                        "h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white"
                      )}
                    >
                      <Icon name="Info" size="20px" color="white" />
                    </span>
                  </div>
                  <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                    <div>
                      <p className="text-sm text-gray-500">
                        {log.message_to_user ?? log.message}{" "}
                      </p>
                    </div>
                    <div className="whitespace-nowrap text-right text-xs text-gray-500">
                      <time dateTime={log.createdAt}>
                        {commonFormatDate(log.createdAt)}
                      </time>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

const getJobsWithResponse = ({ data, log_data, platform }) => {
  const baseJobs = platform.jobs_with_response ?? [];

  if (Array.isArray(baseJobs) && baseJobs.length > 0) {
    return baseJobs;
  }

  const jobsLogs = log_data?.jobs_with_response ?? [];
  const jobsData = data?.jobs_with_response ?? [];

  if (!jobsData && !jobsLogs) {
    return [];
  }
  // determine array with more items
  return [jobsData, jobsLogs].reduce(
    (acc, curr) => (curr.length > acc.length ? curr : acc),
    []
  );
};

const EventDetailsContent = ({ data }) => {
  const platforms = data.platforms;

  return (
    <Box>
      <Heading>Plataformas</Heading>
      <Tabs>
        <TabList>
          {platforms.map((platform, index) => {
            return (
              <Tab key={`${platform.name}-${index}`} textTransform="uppercase">
                {platform.name}
              </Tab>
            );
          })}
        </TabList>

        <TabPanels>
          {platforms.map((platform, index) => {
            const query = platform.query;
            const data = platform.data ?? {};
            const log_data = platform.log_data ?? {};

            const jobsWithResponse = getJobsWithResponse({
              data,
              log_data,
              platform,
            });

            const success = jobsWithResponse.filter(
              (jobResponse) => jobResponse.response?.success
            );

            const failed = jobsWithResponse.filter(
              (jobResponse) => !jobResponse.response?.success
            );

            return (
              <TabPanel key={index}>
                <SimpleGrid columns={{ lg: 2 }} gap={5} minH="50vh">
                  <Box>
                    <div>
                      <ul
                        role="list"
                        className="mt-3 grid grid-cols-1 gap-5 sm:grid-cols-2 "
                      >
                        {/*TODO: open modal when is clicked*/}
                        <StatCard
                          jobs={jobsWithResponse}
                          colorScheme="purple"
                          stat={{
                            name: "Total postulaciones encontradas",
                            bgColor: "bg-purple-600",
                            initials: "TR",
                            value: jobsWithResponse.length,
                          }}
                        />{" "}
                        <StatCard
                          jobs={success}
                          colorScheme="green"
                          stat={{
                            name: "Postulaciones completadas",
                            bgColor: "bg-green-600",
                            initials: "PC",
                            value: success.length,
                          }}
                        />
                        <StatCard
                          colorScheme="red"
                          jobs={failed}
                          stat={{
                            name: "Postulaciones fallidas",
                            bgColor: "bg-red-600",
                            initials: "PO",
                            value: failed.length,
                          }}
                        />
                      </ul>

                      <ApplicationStatus data={data} platform={platform} />

                      <div>
                        <Heading size="md" mt={6}>
                          {" "}
                          Información de filtros de postulación:
                        </Heading>

                        <dl className="sm:divide-y sm:divide-gray-200">
                          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                            <dt className="text-sm font-medium text-gray-500">
                              Profesión
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                              {query.profession}
                            </dd>
                          </div>
                          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                            <dt className="text-sm font-medium text-gray-500">
                              Ciudad
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                              {query.city}
                            </dd>
                          </div>
                          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                            <dt className="text-sm font-medium text-gray-500">
                              Estado
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                              {query.state}
                            </dd>
                          </div>
                          {platform.preview_url ? (
                            <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                              <dt className="text-sm font-medium text-gray-500">
                                Previsualización de la url
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                <a href={platform.preview_url} target="_blank">
                                  {platform.preview_url}
                                </a>
                              </dd>
                            </div>
                          ) : null}
                        </dl>
                      </div>
                    </div>
                  </Box>
                  <Box
                    borderLeftWidth={2}
                    px={4}
                    borderLeftColor="gray.500"
                    maxH="60vh"
                    overflowY="scroll"
                  >
                    <Logs platform={platform} />
                  </Box>
                </SimpleGrid>
              </TabPanel>
            );
          })}
        </TabPanels>
      </Tabs>
    </Box>
  );
};

const statusData = {
  finish: {
    title: "Su postulación ha finalizado",
  },
  initial: {
    title: "No puede ver información de su postulación",
  },
  start: {
    title: "¡Su postulación ha empezado! Te notificaremos cuando finalice",
  },
  scheduled: {
    title:
      "¡Su postulación está en proceso! Su finalización dependerá de la demanda de trabajo a nuestros servidores y cuánto tarde la tuya. Te notificaremos cuando esta finalice",
  },
};

const EventDetails = ({ id }) => {
  const { isLoading, data } = useQuery({
    queryKey: ["payment-events-details", id],
    queryFn: async ({ queryKey }) => {
      return (await client.get(`/payments/${queryKey[1]}`)).data?.data;
    },
    enabled: true,
  });

  if (isLoading) {
    return <Text>Cargando...</Text>;
  }

  if (!data) {
    return (
      <>
        <SEO title="Eventos" description="Lista de eventos" />
        <DocsWrapper>
          <Div
            p={{
              x: { xs: "1.5rem", md: "4rem" },
              t: { xs: "1.5rem", md: "4rem" },
            }}
          >
            <Alert status="info">
              <AlertIcon />
              <AlertTitle>Nada que mostrar</AlertTitle>
              <AlertDescription>No se encontró algún registro</AlertDescription>
            </Alert>
          </Div>
        </DocsWrapper>
      </>
    );
  }

  const paymentStatus = determinePaymentStatus(data);

  const isPaymentPaid = paymentStatus.paid;

  const renderStatusInfo = () => {
    const status = data?.status;

    const dataStatus = statusData[status];

    if (!status || !dataStatus) {
      return null;
    }
    return (
      <Alert status="info" my={2}>
        <AlertIcon />
        <AlertTitle>Estado del proceso:</AlertTitle>
        <AlertDescription>{dataStatus?.title}</AlertDescription>
      </Alert>
    );
  };

  return (
    <>
      <SEO title="Eventos" description="Lista de eventos" />
      <DocsWrapper>
        <Div
          p={{
            x: { xs: "1.5rem", md: "4rem" },
            t: { xs: "1.5rem", md: "4rem" },
          }}
        >
          <div className="px-4 sm:px-6 lg:px-8">
            <div className="sm:flex sm:items-center">
              <div className="sm:flex-auto">
                <div className="flex justify-between">
                  <h1 className="text-xl font-semibold text-gray-900">
                    Detalles del pago
                  </h1>
                  <p>
                    <Badge colorScheme={paymentStatus.colorScheme}>
                      {paymentStatus.message}
                    </Badge>
                  </p>
                </div>

                <p className="mt-2 text-sm text-gray-700">
                  Aquí podrás encontrar más información acerca de tu pago e
                  información relevante a tus postulaciones filtradas por los
                  drivers que escogió
                </p>
                <Div d="flex" bg="info200" p="1rem" rounded="md" m={{ t: "2rem" }}>
                  <Icon
                    name="InfoSolid"
                    size="16px"
                    m={{ r: "0.5rem", t: "0.25rem" }}
                    color="info700"
                  />
                  <Text>
                    IMPORTANTE: FileHire se encuentra en constante desarrollo para brindarte una mejor experiencia.
                    Algunas funciones aún están en desarrollo.{" "}
                  </Text>
                </Div>
                <Div d="flex" bg="success200" p="1rem" rounded="md" m={{ t: "1rem" }}>
                  <Icon
                    name="InfoSolid"
                    size="16px"
                    m={{ r: "0.5rem", t: "0.25rem" }}
                    color="success700"
                  />
                  <Text>
                    Si tuviste tuviste algun problema o duda por favor contáctanos en el{" "}
                    <a href="https://chat.whatsapp.com/H6O23bOjKqGHROfVwT6g28">
                      Grupo de Whatsapp
                    </a>
                  </Text>
                </Div>
              </div>
            </div>
            <div className="mt-8 flex flex-col">
              <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                  {!isPaymentPaid ? (
                    <Alert status="error">
                      <AlertIcon />
                      <AlertTitle>Pago no procesado</AlertTitle>
                      <AlertDescription>
                        No podrás ver nada, hasta que tu pago sea realizado.
                      </AlertDescription>
                    </Alert>
                  ) : (
                    <>
                      {renderStatusInfo()}
                      <EventDetailsContent data={data} />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Div>
      </DocsWrapper>
    </>
  );
};

export default EventDetails;

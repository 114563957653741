import React from "react";
import SEO from "../../components/seo";
import { Div, Icon } from "atomize";
import DocsWrapper from "../../components/common/DocsWrapper";
import { client } from "../../lib/client";
import { Link } from "gatsby";
import {
  Alert,
  AlertDescription,
  AlertTitle,
  Badge,
  Button,
  Flex,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import { useQuery } from "react-query";
import {
  commonFormatDate,
  determinePaymentStatus,
  sortArrByDate,
} from "../../utils";

const headers = [
  { title: "Nombre" },
  { title: "Descripción" },
  { title: "Fecha creado" },
  { title: "Estado" },
  { title: "Acciones" },
];

const EventsTable = ({ payments = [] }) => {
  return (
    <TableContainer>
      <Table variant="striped" colorScheme="blue">
        <TableCaption>Pagos y eventos</TableCaption>
        <Thead>
          <Tr>
            {headers.map((header, index) => (
              <Th {...header} key={index}>
                {header.title}
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {sortArrByDate(payments).map((payment) => {
            const paymentStatus = determinePaymentStatus(payment);
            return (
              <Tr key={payment._id}>
                <Td>{payment.name}</Td>
                <Td>{payment.description}</Td>
                <Td>{commonFormatDate(payment.createdAt)}</Td>
                <Td>
                  <Badge colorScheme={paymentStatus.colorScheme}>
                    {paymentStatus.message}
                  </Badge>
                </Td>
                <Td>
                  <Link to={`/dash/events/${payment._id}`}>
                    <Button colorScheme="blue">Ver eventos</Button>
                  </Link>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
        <Tfoot>
          <Tr>
            {headers.map((header, index) => (
              <Th {...header} key={index}>
                {header.title}
              </Th>
            ))}
          </Tr>
        </Tfoot>
      </Table>
    </TableContainer>
  );
};

const Events = () => {
  const { isLoading, data, refresh } = useQuery({
    queryKey: ["payment-events"],
    queryFn: async () => {
      return (await client.get("/payments")).data?.data;
    },
    enabled: true,
  });

  const { isOpen, onOpen, onClose } = useDisclosure();

  const redirectWizard = () => {
    const urlWizard = process.env.GATSBY_WIZARD_URL || "http://127.0.0.1:5173";
    const token = JSON.parse(localStorage.getItem("@filehire/user")).token;
    window.location.replace(`${urlWizard}?token=${token}`);
  };

  const addApplicationButton = (
    <Button type="button" colorScheme="green" onClick={onOpen}>
      Nueva Aplicación
    </Button>
  );

  return (
    <>
      <SEO title="Eventos" description="Lista de eventos" />

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader> Nueva aplicación</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Serás redirigido al formulario de aplicacion donde podrás
            seleccionar las plataformas a las que quieras postularte y realizar
            tu pago.
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Cerrar
            </Button>
            <Button colorScheme="green" onClick={redirectWizard}>
              Aplicar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <DocsWrapper>
        <Div
          p={{
            x: { xs: "1.5rem", md: "4rem" },
            t: { xs: "1.5rem", md: "4rem" },
          }}
        >
          <div className="px-4 sm:px-6 lg:px-8">
            <div className="sm:flex sm:items-center">
              <div className="sm:flex-auto">
                <h1 className="text-xl font-semibold text-gray-900">
                  Aplicaciones y eventos
                </h1>
                <p className="mt-2 text-sm text-gray-700">
                  Aquí puedes visualizar tus aplicaciones, las cuales están
                  relacionadas con tus pagos y sus eventos. Los eventos
                  básicamente son el historial de postulaciones de los trabajos,
                  donde podrás ver a cuantos trabajos postulaste exitosamente,
                  si hay un error, etc.
                </p>
                <Div d="flex" bg="info200" p="1rem" rounded="md" m={{ t: "2rem" }}>
                  <Icon
                    name="InfoSolid"
                    size="16px"
                    m={{ r: "0.5rem", t: "0.25rem" }}
                    color="info700"
                  />
                  <Text>
                    IMPORTANTE: FileHire se encuentra en constante desarrollo para brindarte una mejor experiencia.
                    Algunas funciones aún están en desarrollo.{" "}
                  </Text>
                </Div>
              </div>
              <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none space-x-2">
                <IconButton
                  aria-label="reload"
                  colorScheme="blue"
                  onClick={refresh}
                  color="white"
                  icon={<Icon name="Refresh" size="20px" color="white" />}
                />
                {addApplicationButton}
              </div>
            </div>
            <div className="mt-8 flex flex-col">
              <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                  {isLoading || !data ? (
                    <Text>loading...</Text>
                  ) : data.length === 0 ? (
                    <Alert
                      status="info"
                      variant="subtle"
                      flexDirection="column"
                      alignItems="center"
                      justifyContent="center"
                      textAlign="center"
                      height="200px"
                    >
                      <span
                        role="img"
                        aria-label="eyes"
                        style={{ fontSize: 55 }}
                        m={{ b: "1.5rem" }}
                      >
                        👀
                      </span>
                      <AlertTitle mt={4} mb={1} fontSize="lg">
                        Aún no has realizado una postulación
                      </AlertTitle>
                      <AlertDescription maxWidth="sm">
                        <Flex flexDir="column" mb={4}>
                          ¿Deseas empezar a aplicar?
                        </Flex>
                        <Flex flexDir="column">{addApplicationButton}</Flex>
                      </AlertDescription>
                    </Alert>
                  ) : (
                    <EventsTable payments={data} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </Div>
      </DocsWrapper>
    </>
  );
};

export default Events;
